<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title"><i class="bi bi-x-octagon-fill me-2 text-danger"></i>Erreur</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="alert alert-warning mb-0">
                        {{message}}
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="close()"><i class="bi bi-x me-2"></i>Fermer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";

    let props = defineProps(["modalId","show","message"]);
    let emits = defineEmits(["close"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    let userLevel = ref(2);

    onMounted( () => {
        
    });

    function close(){
        emits("close");
    }


</script>